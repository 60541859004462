<template>
  <nav>
    <router-link :to="{ name: 'Home' }"><h2>LOGO</h2></router-link>
    <div id="nav">
      <router-link :to="{ name: 'Home' }">Home</router-link>
      <router-link :to="{ name: 'About' }">About</router-link>
    </div>
  </nav>
</template>

<script lang="ts">
export default {
  name: "Navbar",
};
</script>

<style lang="scss" scoped>
nav {
  padding: 0 20rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgb(26, 30, 32);

  h2 {
    display: block;
  }
  a {
    text-decoration: none;
    color: #ffffff;
  }

  #nav {
    display: flex;
    flex-direction: row;
    place-items: center;

    a {
      height: 4rem;
      width: 6rem;
      transition: color 0.2s ease, background 0.2s ease;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.router-link-exact-active {
        color: #3adb8b;
      }

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
</style>